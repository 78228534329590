<template>
  <div class="pay-page">
    <div class="pay-info">
      <div class="head">为 13800138000 充值10元</div>
      <div class="content">
        <p class="pay-text">待支付 <span class="price">10.00</span> 元</p>
        <p class="timer">剩余支付时间：23分19秒</p>
      </div>
    </div>
    <div class="pay-style">
        <h2 class="title">付款方式</h2>
        <div class="pay-list">
          <van-radio-group v-model="radio">
            <van-cell-group>
              <div class="list-item">
                <svg-icon icon-class="icon_zfb" />
                 <van-cell title="支付宝" @click="radio = '1'">
                <template #right-icon>
                  <van-radio name="1" />
                </template>
              </van-cell>
              </div>
              <div class="list-item">
                 <svg-icon icon-class="icon_wx" />
                 <van-cell title="微信" @click="radio = '2'">
                <template #right-icon>
                  <van-radio name="2" />
                </template>
              </van-cell>
              </div>
            </van-cell-group>
          </van-radio-group>
        </div>
    </div>
      <div class="btn-con">
          <button type="button" class="btn">确定支付</button>
        </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { RadioGroup, Radio, Cell, CellGroup } from 'vant'

Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Cell)
Vue.use(CellGroup)
export default {
  name: 'pay',
  data () {
    return {
      radio: '1'
    }
  }
}
</script>

<style lang="scss" scoped>
  .pay-page{
    padding: 2.375rem 1.25rem;
  }
  .pay-info{
    border-radius: 10px;
    background-color: #fff;
    overflow: hidden;
    .head {
      height: 2.5rem;
      line-height: 2.5rem;
      background-color: #01A6FF;
      text-align: center;
      font-size: 0.875rem;
      color: #fff;
    }
    .content{
      padding: 1.75rem 0;
      text-align: center;
      .pay-text{
        font-size: 1rem;
        line-height:1.3125rem;
        font-weight: bold;
        .price{
          color: #ff6b00;
        }
      }
      .timer{
        display: inline-block;
        width: 10rem;
        height:1.75rem;
        line-height: 1.75rem;
        text-align: center;
        background-color: #ebebeb;
        border-radius: 3px;
        font-size: 0.75rem;
        color: #666;
        margin-top: 0.635rem;
      }
    }
  }
  .pay-style{
    margin-top: 1.875rem;
    background-color: #fff;
    border-radius: 10px;
    padding:0.875rem  1rem 1.25rem;
    .title{
      font-size: 0.875rem;
      line-height: 1.125rem;
      color:#333;
    }
    .pay-list{
      margin-top: 0.875rem;
      .list-item{
        height: 3.875rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        border-top:1px solid #EBEBEB;
        &:first-child{
          border-top: none;
        }
        &::after{
          display: none;
        }
      }
      .van-radio-group{
        &::after{
          display: none;
        }
      }
      .van-cell-group{
        &::after{
          display: none;
        }
      }
       .van-cell{
          font-size: 0.875rem;
          padding: 0;
       }
       .svg-icon{
         width: 1.5rem;
         height: 1.5rem;
         margin-right:0.625rem;
       }
    }
  }
  .btn-con{
    margin-top: 10rem;
  }
</style>
